/* eslint-disable react/display-name */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import MaterialTable from 'material-table';
import { useToasts } from 'react-toast-notifications';
import Checkbox from '@material-ui/core/Checkbox';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import { FaqRoutes, RideShareRoutes } from 'client';
import { Button, Grid } from '@material-ui/core';
import { ViewSeo } from 'components/SeoSection/ViewSeo';
import AddFaq from './AddFaq';

const Faq = () => {
  const { addToast } = useToasts();
  const [faqSeo, setFaqSeo] = React.useState();

  const tableColumns = [
    {
      title: 'Show on FE',
      field: 'active',
      render: rowData => (
        <Checkbox
          color="default"
          checked={rowData.active}
          onChange={event => {
            handleChange(event.target.checked, rowData);
          }}
        />
      ),
    },
    {
      title: 'Title',
      field: 'title',
    },
    {
      title: 'Text Color',
      field: 'textColor',
    },
  ];

  const [state, setState] = React.useState({
    data: [],
  });
  const [isAddItem, setIsAddItem] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [rideShareData, setRideShareData] = useState(null);

  const handleChange = async (value, data) => {
    const newData = { ...data, active: value };
    try {
      const response = await FaqRoutes.updateFaqData(newData._id, newData);
      if (response.status === 'success') {
        addToast(response.status);
        loadFaqs();
        goBack();
      } else {
        addToast(response.status, { severity: 'error' });
      }
    } catch (err) {
      addToast(err.message, { severity: 'error' });
    }
    loadFaqs();
  };

  const loadFaqs = React.useCallback(async () => {
    try {
      const response = await FaqRoutes.getFaqList();
      const data = response.data;
      setState(pre => ({
        ...pre,
        data: data,
      }));
    } catch (err) {
      const message = err.message || err;
      addToast(message, { severity: 'info' });
    }
  }, [addToast]);
  const loadSeo = React.useCallback(async () => {
    try {
      const response = await RideShareRoutes.getHomeRideShare();
      const filteredData = response.data.find(item => item.cityName.toLowerCase() === 'faq');
      setFaqSeo(filteredData);
    } catch (err) {
      const message = err.message || err;
      addToast(message, { severity: 'info' });
    }
  }, [addToast]);

  React.useEffect(() => {
    loadFaqs();
    // eslint-disable-next-line
  }, [loadFaqs]);
  React.useEffect(() => {
    loadSeo();
  }, [loadSeo]);

  const handleDelete = async data => {
    const response = await FaqRoutes.deleteFaqData(data._id);
    if (response.status === 'success') {
      addToast('deleted');
      loadFaqs();
    } else {
      addToast(response.status, { severity: 'error' });
    }
  };

  const handleAddItem = async newData => {
    const response = await FaqRoutes.postFaqData(newData);
    if (response.status === 'success') {
      addToast(response.status);
      loadFaqs();
      goBack();
    } else {
      addToast(response.status, { severity: 'error' });
    }
  };
  const handleUpdateSeo = async (_id, newData) => {
    try {
      const response = await RideShareRoutes.updateRideShare(_id, newData);
      if (response.status === 'success') {
        addToast(response.status);
        loadSeo();
      } else {
        addToast(response.status, { severity: 'error' });
      }
    } catch (err) {
      addToast(err.message, { severity: 'error' });
    }
    loadSeo();
  };

  const handleUpdateItem = async (_id, newData) => {
    try {
      const response = await FaqRoutes.updateFaqData(_id, newData);
      if (response.status === 'success') {
        addToast(response.status);
        loadFaqs();
        goBack();
      } else {
        addToast(response.status, { severity: 'error' });
      }
    } catch (err) {
      addToast(err.message, { severity: 'error' });
    }
    loadFaqs();
  };

  const goBack = () => {
    setIsAddItem(false);
    setRideShareData(null);
    setShowAddModal(false);
  };

  return (
    <>
      <GridContainer>
        {showAddModal ? (
          <AddFaq goBack={goBack} data={rideShareData} updateMode={!isAddItem} handleAddItem={handleAddItem} handleUpdateItem={handleUpdateItem} loadFaqs={loadFaqs} />
        ) : (
          <GridItem xs={12} sm={12} md={12}>
            <Button
              onClick={() => {
                setShowAddModal(true);
                setIsAddItem(true);
              }}
              variant="contained"
              style={{ marginBottom: '1rem' }}
            >
              Add new Data
            </Button>
            <MaterialTable
              title="FAQ"
              columns={tableColumns}
              data={state.data}
              actions={[
                {
                  icon: 'edit',
                  onClick: (e, rowData) => {
                    setRideShareData(rowData);
                    setIsAddItem(false);
                    setShowAddModal(true);
                  },
                },
                {
                  icon: 'delete',
                  onClick: (e, rowData) => {
                    handleDelete(rowData);
                  },
                },
              ]}
            />
          </GridItem>
        )}
      </GridContainer>
      {faqSeo && (
        <Grid container direction="column">
          <Grid container alignItems="center">
            <Grid container style={{ backgroundColor: 'white', paddingBottom: 4, paddingTop: '1em', borderRadius: '0.5em' }}>
              <ViewSeo data={faqSeo} handleUpdateItem={handleUpdateSeo} />
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

Faq.propTypes = {
  value: PropTypes.string,
};

Faq.defaultProps = {
  value: '',
};

export default Faq;
