import api from './api';
import unify from './api/unify';

export const getFaqList = () => unify(api.get('/faq-list'));

export const postFaqData = faqPostData => {
  return unify(api.post('/faq-list', { ...faqPostData }));
};

export const updateFaqData = (id, updateFaqData) => unify(api.put(`/faq-list/${id}`, updateFaqData));

export const deleteFaqData = id => unify(api.delete(`/faq-list/${id}`));
