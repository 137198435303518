import React from 'react';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { FormHelperText } from '@material-ui/core';
import { useToasts } from 'react-toast-notifications';

import LabeledTextField from '../RideShareCities/LabeledTextField';
import LabeledImageUploader from '../RideShareCities/LabeledImageUploader';
import LabeledColorPicker from '../RideShareCities/LabeledColorPicker';
import { RideShareRoutes } from 'client';

export default function AddFaq(props) {
  const { handleAddItem, handleUpdateItem, goBack, updateMode, data } = props;
  const { addToast } = useToasts();
  const [state, setState] = React.useState(
    data || {
      title: '',
      textColor: '#000000',
      img: {
        publicId: '',
        src: '',
      },
    }
  );

  const onUpdateClick = () => {
    if (!updateMode) {
      handleAddItem({ ...state });
    } else {
      handleUpdateItem(data._id, { ...state });
    }
  };

  const removeImage = async () => {
    try {
      await RideShareRoutes.deleteRideShareImage(data._id);
      addToast('Image is removed');
      setState({ ...state, img: { src: null } });
      props.loadFaqs();
    } catch (error) {
      addToast(error.message, { severity: 'error' });
    }
  };

  const handleTextField = field => event => {
    setState({ ...state, [field]: event.target.value });
  };

  const handleImage = img => setState({ ...state, img });
  const handleColor = ({ hex }) => setState({ ...state, textColor: hex });

  return (
    <Grid container direction="column">
      <Grid container alignItems="center">
        <Typography align="center" variant="h5" style={{ marginLeft: 16 }}>
          {!updateMode ? 'Add' : 'Update'} FAQ
        </Typography>
        <Button style={{ marginLeft: 16 }} onClick={goBack} variant="outlined" color="primary">
          Back to List
        </Button>
        <Button style={{ marginLeft: 16 }} onClick={onUpdateClick} variant="outlined" color="primary">
          {!updateMode ? 'Add' : 'Update'} FAQ
        </Button>
      </Grid>
      <Grid container>
        <LabeledTextField
          maxLength={35}
          title="Title"
          value={state.title}
          onChange={handleTextField('title')}
          helperText={
            <FormHelperText>
              <strong>It will appear on the image</strong>
            </FormHelperText>
          }
        />
        <LabeledColorPicker title="Text color" value={state.textColor} example={state.title} exampleImage={state.img.src ?? null} onChange={handleColor} />
        <LabeledImageUploader title="Image" value={state.img} onChange={handleImage} handleRemoveImage={removeImage} />
      </Grid>
    </Grid>
  );
}
